import React, {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import {SafeAreaView, StyleSheet, View, Image, Text} from "react-native-web";
import SignatureCanvas from 'react-signature-canvas'
import {get}from 'lodash';
import {DS_BLUE, DS_GOLD, GRAY, LIGHT_GRAY} from "../Consts/colors";
import RoundButton from "../Components/Buttons/RoundButton";
import {SCREEN_HEIGHT, SCREEN_WIDTH} from "../Consts";
import {SubmitSection, SubscribeToSections} from "../Store/Actions/sectionActions";
import Loading from "../Components/Loading/loading";

const SignatureScreen=({navigation,route, SubmitSection, SubscribeToSections})=>{

    const marks = get(route.params,'marks', null);
    const demo = get(route.params,'demo', true);


    const [error,setError]=useState(null);
    const [loading,setLoading]=useState(false);
    const signatureRef = useRef(null)

    const onClear=()=>{
        if(signatureRef){
           signatureRef.current.clear();
        }
    }



    const onSave=()=>{

        if(signatureRef.current.isEmpty()){
            setError('Please add your signature!')
        }else{
            const base64 =signatureRef.current.toDataURL();

            if(demo){
                navigation.popToTop();
            }else{
                setLoading(true);
                //console.log('signatureMarks',marks);
                SubmitSection(marks,base64,res=>{
                    setLoading(false);
                    if(res.status===1){
                        SubscribeToSections();
                        setTimeout(()=>{
                            navigation.popToTop();
                        },1000)
                    }else{
                        setError(res.msg)
                    }
                })
            }
        }

    }

    return (
        <SafeAreaView style={styles.container}>
            <Loading
                visible={loading}
                text={'Submitting Marks...'}
            />
            <View style={{
                alignItems:'center',
                marginVertical:15
            }}>
                <Image
                    source={require('../../assets/Main_Logo.png')}
                    resizeMode={'contain'}
                    style={styles.image}
                />
                <Text style={{color:DS_BLUE, fontSize:19}}>
                    Please add your signature below.
                </Text>
            </View>
            <View
                style={{
                    width:SCREEN_WIDTH*0.95,
                    height:SCREEN_HEIGHT*0.7,
                    alignItems:"center"
                }}
            >

                <SignatureCanvas
                    ref={signatureRef}
                    penColor={DS_BLUE}
                    canvasProps={{
                        width:SCREEN_WIDTH*0.95,
                        height:SCREEN_HEIGHT*0.6,
                    }}
                    backgroundColor={'#fff'}
                />
                {error && (
                    <Text style={styles.errorText}>
                        {error}
                    </Text>
                )}

                <RoundButton
                    title={'Clear Signature'}
                    color={'gold'}
                    style={{
                        marginTop:10,
                        width:SCREEN_WIDTH*0.5
                    }}
                    onPress={onClear}
                />
            </View>
            <View style={styles.buttonRow}>
                <RoundButton
                    title={'Save Marks'}
                    color={'blue'}
                    onPress={onSave}
                />
            </View>

        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container:{
        backgroundColor:LIGHT_GRAY,
        flex:1,
        alignItems:'center'
    },
    image:{
        height:50,
        width:'auto',
    },
    buttonRow:{
        bottom:25,
        position:'absolute',
        alignItems:'center',
        width:SCREEN_WIDTH
    },
    errorText:{
        color:'red'
    }
});

export default connect(null,{SubmitSection, SubscribeToSections})(SignatureScreen);
