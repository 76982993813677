import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {connect}from 'react-redux';
import EventStack from "./app/EventStack";
import TestingStack from "./testing/TestingStack";
import AuthStack from "./auth/AuthStack";
const MainStack=createStackNavigator();
const {Navigator,Screen}=MainStack;

const AppStack=({isLoggedIn})=>(
    <Navigator
        initialRouteName={'Auth'}
        headerMode={'screen'}
    >
        { isLoggedIn && (
            <Screen
                name={'App'}
                component={EventStack}
                options={{headerShown:false}}
            />
        )}
        {!isLoggedIn && (
            <Screen
                name={'Auth'}
                component={AuthStack}
                options={{headerShown:false, title:'Dance Suite'}}
            />

        )}
        <Screen
            name={'Testing'}
            component={TestingStack}
            options={{headerShown:false}}
        />

    </Navigator>
);


const mapStateToProps=(state)=>{
    const {eventId}=state.adjudicator;
    return {
        isLoggedIn:!! eventId
    }
}
export default connect(mapStateToProps)(AppStack);

