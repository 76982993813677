import React from 'react';
import {Image, StyleSheet} from "react-native-web";

const Logo=()=>(
    <Image
        style={styles.image}
        source={require('../../../assets/Main_Logo.png')}
    />
)
const styles= StyleSheet.create({
    image:{
        height:30,
        width:'auto',
        resizeMode:'contain'
    }
});
export default Logo;
