import {combineReducers} from "redux";

import EventReducer from './eventReducer';
import AdjudicatorReducer from "./AdjudicatorReducer";
import Sections from './sections';
import Demo from './demoReducer';

export default combineReducers({
    event:EventReducer,
    adjudicator:AdjudicatorReducer,
    sections:Sections,
    demo:Demo
});

