import React from 'react';
import {StyleSheet,View, Text} from "react-native";
import Proptypes from 'prop-types';
import {DS_GOLD} from "../../Consts/colors";

const NameCard=({name})=>(
    <View style={styles.container}>
        <Text
            numberOfLines={1}
            allowFontScaling={true}
            style={styles.text}
        >
            {name}
        </Text>
    </View>
)

const styles = StyleSheet.create({
    container:{
        backgroundColor:'#fff',
        width:'100%',
    },
    text:{
        alignSelf:'center',
        marginLeft:10,
        marginRight:10,
        color:DS_GOLD,
        fontSize:20,
        marginTop:10,
        marginBottom:10
    }
});

NameCard.propTypes={
    name:Proptypes.string.isRequired
}

export default NameCard;
