import DemoEvent from '../../../Temp_data/competition.json';
import axios from '../../Api/axiosApi';
import {LOAD_ADJ, CLEAR_ADJ} from "../Reducers/AdjudicatorReducer";
import {ADD_EVENT, CLEAR_EVENT} from "../Reducers/eventReducer";
import {CLEAR_SECTIONS} from "../Reducers/sections";



export const DemoLogin=(code,callback)=>dispatch=>{

    if(code === DemoEvent.adjudicator.loginCode){

        const {name, letter, Id}=DemoEvent.adjudicator;


        dispatch({type:LOAD_ADJ,payload:{name,letter,id:Id,panels:DemoEvent.panels, eventId:'DEMO', deviceKey: 'Demo'}})
        dispatch({type:ADD_EVENT,payload:DemoEvent.eventDetails})
        callback({status:1, name:name})

    }else{
        callback({status:2,msg:'Error: Incorrect Code'})
    }
}

export const AuthLogin=(code,callback)=>async (dispatch)=>{

    const response = await axios.post('/auth/login',{code});

    if(response.data.status===1){

        //console.log(response.data);
        const {adjInfo, eventInfo, panels, deviceKey}=response.data.data;

        dispatch({type:LOAD_ADJ,payload:{...adjInfo,panels, deviceKey,eventId:eventInfo.id}})
        dispatch({type:ADD_EVENT,payload:eventInfo})
        callback({status:1,name:adjInfo.name})

    }else{
        callback({status:2,msg:response.data.msg})
    }




}

export const AuthLogout=(callback)=>async (dispatch, getState)=>{

    const {eventId, letter,name, deviceKey, id}=getState().adjudicator

    if(eventId==='DEMO'){
        dispatch({type:CLEAR_EVENT});
        dispatch({type:CLEAR_ADJ});
        dispatch({type:CLEAR_SECTIONS});
        callback({status:1});
    }else{
        const response = await axios.post('/auth/logout',{
            eventId,
            adjInfo:{
                letter,
                name,
                id
            }
        });
        if(response.data.status===1){
            dispatch({type:CLEAR_EVENT});
            dispatch({type:CLEAR_ADJ});
            dispatch({type:CLEAR_SECTIONS});
            callback({status:1});

        }else{
            callback({status:2,msg:response.data.msg})
        }
    }


}
