
export const IS_DEMO ='isDemo';
export const EXIT_DEMO ='exitDemo';

export default (state=false,{type,payload})=>{
    switch (type){
        case IS_DEMO:
            return true;
        case EXIT_DEMO:
            return false;
        default:
            return state;
    }
}
