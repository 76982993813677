import React from 'react';
import {StyleSheet,View,Text} from "react-native-web";
import {DARK_GREY, DS_BLUE, DS_GOLD} from "../../Consts/colors";

const EventCard=({details})=>(
        <>
            <View style={styles.container}>
                <View style={styles.leftView}>
                    <Text style={styles.Letter}>{
                        details?.adjudicator?.letter
                    }</Text>
                </View>
                <View style={styles.rightView}>
                    <View style={styles.row}>
                        <Text style={[styles.title,{color:DS_BLUE}]}>Title</Text>
                        <Text style={[styles.title,styles.goldText]}>{details.title}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.venue,{color:DS_BLUE}]}>Venue</Text>
                        <Text style={[styles.venue,styles.goldText]}>{details.venue}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.date,{color:DS_BLUE}]}>Date</Text>
                        <Text style={[styles.date,styles.goldText]}>{details.date}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.official,{color:DS_BLUE}]}>Scrutineer</Text>
                        <Text style={[styles.official,styles.goldText]}>{details.scrutineer}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.official,{color:DS_BLUE}]}>Chairperson</Text>
                        <Text style={[styles.official,styles.goldText]}>{details.chairperson}</Text>
                    </View>
                </View>
            </View>
        </>
);
const styles=StyleSheet.create({
    container:{
        flexDirection:'row',
        backgroundColor:'#fff',
        borderBottomWidth:1,
        borderBottomColor:DARK_GREY
    },
    leftView:{
      width:'35%',
        alignItems:'center',
        justifyContent:'center'
    },
    rightView:{
      width:'65%',
      marginBottom:5,
        marginTop:5
    },
    row:{
      flexDirection: 'row',
      //justifyContent: 'space-Around'
    },
    Letter:{
        fontSize:60
    },
    title:{
        fontSize:15,
    },
    venue:{
        fontSize:12
    },
    date:{
        fontSize:10
    },
    official:{
        fontSize:11
    },
    goldText:{
        color:DS_GOLD,
        marginLeft:12
    }
})
export default EventCard;
