import {useState,useReducer, useEffect}from 'react';
import {keys}from 'lodash';

const initialState={
    callBacks:[],
    selected:[],
    currentDance:null,
    error:null,
    marks:{},
    loading:false,
    confirmModal:false
};

export const LOAD_DANCE='load_dance';
export const CALLBACK_DANCER='callBackDancer';
export const SELECT_DANCER='selectDancer';
export const ADD_TO_CALLBACK='addToCallback';
export const ERROR='error';
export const ADD_MARKS='addMarks';
export const ADD_FINAL_MARKS='addFinalMarks';
export const LOADING ='loading';
export const MODAL='confirmModal';

const reducer=(state,{type,payload})=>{
    switch(type){
        case MODAL:
            return {...state,confirmModal:!state.confirmModal}
        case LOADING:
            return {...state,loading:!state.loading}
        case ADD_FINAL_MARKS:
            return {
                ...state,
                callBacks: [],
                selected: [],
                error:null,
                currentDance:null,
                confirmModal: false
            }
        case ADD_MARKS:
            return {
                ...state,
                marks:{...state.marks,[state.currentDance]:state.callBacks},
                callBacks: [],
                selected:[],
                error:null,
                currentDance: payload,
                confirmModal: false,
            }
        case LOAD_DANCE:
            return {...state,currentDance:payload}
        case ERROR:
            return {...state,error:payload}
        case ADD_TO_CALLBACK:
            return {...state,selected: payload.selected,callBacks:payload.callBacks,error:null}
        case CALLBACK_DANCER:
            return {...state,callBacks: payload,error:null}
        case SELECT_DANCER:
            return {...state,selected:payload,error:null}
        default:
            return {...state};
    }
}

export default (callbackLimit, dances)=>{
    const [state,dispatch]=useReducer(reducer,initialState);
    const {selected,callBacks, marks}=state;

    // Functions
    const dancerPress=(dancer)=>{
        if(callBacks.includes(dancer)){
            dispatch({type:CALLBACK_DANCER,payload:callBacks.filter(d=>d!==dancer)});
        }else if(selected.includes(dancer)){
            if(callBacks.length<callbackLimit){
                dispatch({
                    type:ADD_TO_CALLBACK,
                    payload:{
                        callBacks:[...callBacks,dancer],
                        selected:selected.filter(d=>d!==dancer)
                    }
                });
            }else{
                dispatch({type:ERROR,payload:'Whoa, too many dancers selected!'})
            }

        }else{
            // if not selected
            dispatch({type:SELECT_DANCER,payload:[...selected,dancer]});
        }
    }



    // Helpers
    const progress= callBacks.length==callbackLimit;

    const buttonText=keys(marks).length===dances.length-1?'Submit':'Next Dance';

    return {state,dispatch, dancerPress, progress, buttonText}
}
