import React from "react";
import {View,Text,TouchableOpacity, StyleSheet} from "react-native-web";
import {DS_BLUE, DS_GOLD} from "../../Consts/colors";

const MarkingRow=({dancer,mark,onMarkPress})=>{
    return (
        <View style={styles.container}>
            <View style={styles.cardView}>
                <Text style={styles.cardTitle}>{dancer}</Text>
            </View>
            <TouchableOpacity style={styles.cardView} onPress={onMarkPress}>
                <Text style={styles.cardTitle}>
                    {mark}
                </Text>
            </TouchableOpacity>
        </View>
    )
}

const styles=StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-around',
        borderBottomWidth:1,
        borderColor: DS_GOLD,
    },
    cardView:{
        height:50,
        width:90,
        marginTop:15,
        marginBottom:15,
        backgroundColor:'#fff',
        borderRadius:15,
        margin:5,
        alignItems:'center',
        justifyContent: 'center',
        borderWidth:1,
        borderColor:DS_BLUE
    },
    cardTitle:{
        fontSize:20,
        color:DS_BLUE,
        fontWeight:'bold'
    }

});
export default MarkingRow;
