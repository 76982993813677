import DemoEvent from '../../../Temp_data/competition.json';
import {get}from 'lodash';
import axios from '../../Api/axiosApi';
import {CLOSE_SECTION, OPEN_SECTION, SUB_SECTIONS} from "../Reducers/sections";

export const SubscribeToSections=()=>async (dispatch, getState)=>{

    const state = getState();
    const {eventId, letter, panels}=state.adjudicator;

    const response = await axios.post('/sections/all',{
        eventId,
        panels,
        adjLetter:letter
    })

    if(response.data.status===1){
        dispatch({type:SUB_SECTIONS,payload:response.data.data});
    }
}

export const SubmitSection=(allMarks,signature,callback)=>async(dispatch,getState)=>{
    const state = getState();
    const {eventId, letter, deviceKey}=state.adjudicator;

    if(eventId==='DEMO'){
        callback({status:1,msg:'Submitted Successfully'})
    }else{
        const response = await axios.post('/sections/marks',{
            eventId,
            letter,
            device:deviceKey,
            allMarks,
            signature
        })
        console.log('response',response)

        if(response.data.status===1){
            callback({status:1,msg:'Submitted Successfully'})
        }else{
            callback({status:2,msg:response.data.msg})
        }
    }

};

export const RefreshSections=()=>dispatch=>{
    console.log('Refresh ')
}

export const DemoSubScribeToSections=()=>dispatch=>{
        const Sections=DemoEvent.sections
    dispatch({type:SUB_SECTIONS,payload:Sections});
}

export const OpenSection=(section,callback)=>dispatch=>{
    dispatch({type:OPEN_SECTION,payload:section});
    callback()
};

export const CloseSection=(callback)=>dispatch=>{
    dispatch({type:CLOSE_SECTION});
    callback();
}
