import React from "react";
import {Button} from "react-native-elements";
import {StyleSheet} from "react-native-web";
import {SELECTED,CONFIRMED} from "../../Consts/marking";
import {DS_BLUE, DS_GOLD} from "../../Consts/colors";

const check=(status)=>{
    switch (status){
        case SELECTED:
            return DS_GOLD;
        case CONFIRMED:
            return DS_BLUE;
        default:
            return '#FFF'
    }
}


const MarkingButton=({onPress,title, status})=>(
    <Button
        title={title}
        titleStyle={{color: !status ?DS_BLUE:'#fff'}}
        onPress={onPress}
        containerStyle={[
            styles.container,
            {
                borderWidth: 1,
                borderColor: check(status) === '#fff' && DS_BLUE
            }
        ]}
        buttonStyle={{
            backgroundColor:check(status),
        }}
    />
);
const styles=StyleSheet.create({
    container:{
        width:'28%',
        borderRadius:15,
        margin:'2%'
    }
});

export default MarkingButton;
