import { Dimensions }from 'react-native';

export const SCREEN_WIDTH=Dimensions.get('window').width;
export const SCREEN_HEIGHT=Dimensions.get('window').height;



export default {
    SCREEN_HEIGHT,
    SCREEN_WIDTH
}
