import React from "react";

import Logo from './Logo';
import {DS_GOLD} from "../../Consts/colors";

export const STANDARD_HEADER={
    headerTitle:Logo,
    headerTintColor:DS_GOLD,
   headerTitleStyle:{
        alignSelf:'center',
        textAlign:'center'
   }
}

export default {
    STANDARD_HEADER,
}
