import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import LoginScreen from "../../Screens/LoginScreen";
const Stack=createStackNavigator();
const {Navigator, Screen}=Stack;

export default ()=>{
    return (
        <Navigator
            headerMode={'screen'}
            initialRouteName={'Login'}
        >
            <Screen
                name={'Login'} component={LoginScreen}
                options={{headerShown:false, title:'Dance-Suite - Login'}}
            />
        </Navigator>
    )
}
