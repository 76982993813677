import axios from 'axios';
import {localFirebase} from "../../config";

const baseURL=localFirebase?
    'http://localhost:5000/dance-suite/us-central1/adjWeb':
    'https://us-central1-dance-suite.cloudfunctions.net/adjWeb'


const instance= axios.create({
    baseURL,
    headers: {"Access-Control-Allow-Origin": "*"}
})
export default instance;

/*
 url https://us-central1-dance-suite.cloudfunctions.net/adjWeb

 End Points

 /auth/login
 {code:""}

 /auth/logout

 /sections/all
 {
    adjLetter,
    eventId,
    panels
 }

 /sections/marks
 {
    allMarks:[],
    eventId,
    letter,
    device
 }



 */
