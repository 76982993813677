import React from 'react';
import {View, Text, SafeAreaView, ScrollView} from 'react-native-web';

export default ({navigation})=>{

    const array = new Array(25).fill(1).map((n,i)=>i);

    return (
        <ScrollView contentContainerStyle={{flex:1}}>
                {array.map(n=>(
                    <View
                        style={{
                            height:200,
                            borderWidth:1,
                            borderColor:'red',
                            alignItems:'center',
                            justifyContent:'center'
                        }}
                    >
                        <Text style={{fontSize:100}}>{n.toString()}</Text>
                    </View>
                ))}
        </ScrollView>
    )
}
