import React from 'react';
import {StyleSheet, View, ActivityIndicator, Text, Platform,Modal} from "react-native-web";
import WebModal from 'modal-react-native-web';

const Loading = ({visible,text})=>{

    const Content =()=> (
        <View style={styles.view}>
            <ActivityIndicator
                size={'large'}
                color={'white'}
            />
            <Text style={styles.text}>
                {text?text:'Loading...'}
            </Text>
        </View>
    )


    return Platform.OS ==='web'?
        (
            <WebModal
                animationType="fade"
                transparent={true}
                visible={visible}
            >
                {Content()}
            </WebModal>
        ):
        (
        <Modal
            visible={visible}
        >
            {Content()}
        </Modal>
    )

}
const styles=StyleSheet.create({
    view:{
        justifyContent:'center',
        alignItems:'center',
        flex:1,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    text:{
        color:'white',
        marginTop:15,
        fontSize:15
    }
})

export default Loading;
