import React from "react";
import {View, StyleSheet, Text, FlatList} from "react-native-web";
import {DARK_GREY, DS_BLUE, DS_GOLD} from "../../Consts/colors";

const RenderItem=({item})=>(
    <Text style={styles.panels}>
        {item.number}
    </Text>
)

const PanelCard=({panels})=>(
    <View style={styles.container}>
        <Text style={styles.title}>Panels</Text>
        <FlatList
            horizontal
            data={panels}
            renderItem={RenderItem}
            keyExtractor={(item)=>item}
        />
    </View>
);

const styles=StyleSheet.create({
    container:{
        backgroundColor:'#fff',
        borderBottomWidth:1,
        borderBottomColor:DARK_GREY,
        minHeight:50,
        justifyContent:'center'
    },
    title:{
        marginLeft:10,
        fontSize:14,
        fontWeight:'bold',
        alignSelf:'center',
        color:DS_GOLD
    },
    panels:{
        marginLeft:5,
        marginRight:5,
        fontSize:12,
        color:DS_BLUE
    }
});

export default PanelCard;
