import React from 'react';
import {Button} from "react-native-elements";
import Icon from 'react-native-vector-icons/FontAwesome';
import colorCheck from "./functions";
import { MaterialIcons } from '@expo/vector-icons';

export default ({icon, onPress, color})=>(
    <Button
        type="clear"
        //title={'Refresh'}
        icon={
            <MaterialIcons
                name={icon}
                size={24}
                color={colorCheck(color)} />
        }
        onPress={onPress}
    />
)
