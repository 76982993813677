import { StatusBar } from 'expo-status-bar';
import 'react-native-gesture-handler';
import React from 'react';
import {Provider}from 'react-redux';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';


//Screens
import {store} from "./src/Store";
import AppStack from "./src/navigation/AppStack";
import TestScreen from "./src/Screens/TestScreen";



export default function App() {
  return (
          <Provider store={store} style={styles.container}>
              <NavigationContainer>
                  <AppStack/>
              </NavigationContainer>
          </Provider>

  );
}

export function App2(){
    return (
        <TestScreen/>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
