import React, {useLayoutEffect, useState, useEffect} from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView} from "react-native";

import { keys, find}from 'lodash';
import {connect}from 'react-redux';

import ClearButton from "../Components/Buttons/ClearButton";
import FinalMarking from "../Components/Marking/FinalMarking";
import RoundMarking from "../Components/Marking/RoundMarking";
import ConfirmModal from "../Components/Modals/ConfirmModal";
import {DS_BLUE} from "../Consts/colors";
import {CloseSection, SubmitSection, SubscribeToSections} from "../Store/Actions/sectionActions";

const SectionScreen=({navigation, route,section, demo, CloseSection, SubmitSection, SubscribeToSections})=>{


    useLayoutEffect(()=>{
        navigation.setOptions({
            headerLeft:()=>(<ClearButton title={'Back'} onPress={()=>setConfirmModal(true)}/>),
        })
    },[navigation]);

    // store marks for submission once all marks have been entered
    const [sectionMarks,setSectionMarks]=useState([]);
    const [render,setRender]=useState(0);

    // state for the section
    const [data,setData] =useState([])
    useEffect(()=>{
        setData(section);
    },[]);

    const [confirmModal,setConfirmModal]=useState(false);
    const [error, setError]=useState(null);

    const onSubmit=(round,marks)=>{
        const actualMarks = sectionMarks;
        actualMarks.push({
            marks,
            roundInfo:round
        });
        console.log('actualMarks',actualMarks);

        if(actualMarks.length === section.length){
            // submit data & move to signature screen
            navigation.replace('Signature',{
                marks:actualMarks.reduce((a,v)=>{
                    a.push({
                        roundId:v.roundInfo.roundId,
                        sectionId:v.roundInfo.sectionId,
                        itemNo:v.roundInfo.itemNo,
                        roundType:v.roundInfo.roundType,
                        sectionName:v.roundInfo.title,
                        marks:v.marks
                    })
                    return a;
                },[]),
                demo
            });
        }
        else{
            setSectionMarks(actualMarks);
            setRender(render+1);
        }
    }

    const handleGoBack=()=>{
        CloseSection(()=>navigation.navigate('EventScreen'))
    }

    return (
        <SafeAreaView style={styles.container}>
            <ConfirmModal
                title={'Alert!!'}
                content={
                    <Text style={{color:DS_BLUE}}>
                        Are you sure you want to go back ?
                        {`\n \n`}
                        Note - this will delete any un-submitted data, and cannot be undone.
                    </Text>
                }
                visible={confirmModal}
                onConfirm={handleGoBack}
                onClear={()=>setConfirmModal(false)}
            />
            {error && (
                <Text style={styles.error}>
                    {error}
                </Text>
            )}

            <ScrollView contentContainerStyle={styles.scrollContainer}>
                {data.length  && data.map((round, index)=>{
                    //console.log('Round Check',round.roundType==='Final' && !sectionMarks[round.roundId],keys(sectionMarks).length)
                    if(round.roundType==='Final' && !find(sectionMarks,{roundInfo: {roundId:round.roundId}})){
                        return(
                            <FinalMarking
                                index={index}
                                roundData={round}
                                onSubmit={onSubmit}
                            />
                        )
                    }else if(!find(sectionMarks,{roundInfo: {roundId:round.roundId}})){
                        return (
                            <RoundMarking
                                index={index}
                                roundData={round}
                                onSubmit={onSubmit}
                            />
                        )
                    }else{
                        return null
                    }
                })
                }
            </ScrollView>
        </SafeAreaView>
    )

}

const styles=StyleSheet.create({
    container:{
        flex:1,
    },
    scrollContainer:{
        marginBottom:50,
        //minHeight:'100%'
    },
    error:{
        alignSelf:'center',
        color:'red',

    }
});
const mapStateToProps=(state)=>{
    return {
        section:state.sections.judging,
        demo:state.adjudicator.eventId==='DEMO'
    }
}

export default connect(mapStateToProps, {CloseSection, SubmitSection, SubscribeToSections})(SectionScreen);
