import React, {useLayoutEffect, useEffect, useState, useCallback} from 'react';
import {StyleSheet, View, Text, Button, FlatList} from "react-native";
import {connect}from 'react-redux';
import {DemoSubScribeToSections,SubscribeToSections, OpenSection, RefreshSections} from "../Store/Actions/sectionActions";
import {AuthLogout} from "../Store/Actions/AuthActions";
import EventCard from "../Components/Cards/EventCard";
import PanelCard from "../Components/Cards/PanelCard";
import SectionCard from "../Components/Cards/SectionCard";
import {DS_BLUE} from "../Consts/colors";
import NameCard from "../Components/Cards/NameCard";
import IconButton from "../Components/Buttons/IconButton";
import ConfirmModal from "../Components/Modals/ConfirmModal";
import Loading from "../Components/Loading/loading";

const EventScreen=({
   navigation,
   data,
   adjudicator,
   allSections,
   subscribed,
   DemoSubScribeToSections,
   SubscribeToSections,
   OpenSection,
   AuthLogout
})=>{
    useLayoutEffect(()=>{
        navigation.setOptions({
            headerLeft:()=>(<IconButton icon={'exit-to-app'} onPress={()=>setConfirmModal(true)} color={'blue'} />),
            headerRight:()=>(<IconButton icon={'refresh'} onPress={()=>SubscribeToSections()} color='gold'/>),
        })
    });


    const [confirmModal,setConfirmModal]=useState(false);
    const [loading,setLoading]=useState(false);

    const logout=()=>{
        setLoading(true)
        AuthLogout(res=>{
            setLoading(false);
            setConfirmModal(false);
            if(res.status===1){
               //navigation.popToTop();
            }

        })
    }

    useEffect(()=>{
        if(!subscribed && adjudicator.eventId){
           if(adjudicator.eventId==='DEMO'){
                // load demo content
               DemoSubScribeToSections()
           }else{
                // load actual content
              SubscribeToSections();
           }
        }
    },[subscribed, adjudicator.eventId])

    const renderItem=({item})=>{
        return(
            <SectionCard
                sectionData={item}
                onPress={()=>{
                    OpenSection(item, res=>navigation.navigate('SectionScreen'))
                }}
            />
        )
    }

    return (
        <View style={styles.container}>
            <Loading
                visible={loading}
                text={'Logging out...'}
            />
            <ConfirmModal
                visible={confirmModal}
                title={'Confirm Logout'}
                content={
                    <Text style={{color:DS_BLUE}}>Confirm you want to logout,{'\n'} Note: this will clear all unsaved data.</Text>}
                onClear={()=>setConfirmModal(false)}
                onConfirm={logout}
            />
            <EventCard
                details={{...data, adjudicator}}
            />
            <PanelCard panels={adjudicator.panels}/>
            <NameCard name={adjudicator.name}/>
            <Text style={styles.text}>Below are all the upcoming sections you will be adjudicating.</Text>

            {allSections.length &&
            (
                <FlatList
                    data={allSections}
                    renderItem={renderItem}
                    keyExtractor={({item, index})=>index}
                />
            )}
        </View>
    )
}

const styles=StyleSheet.create({
    container:{
        flex:1
    },
    text:{
        textAlign:'center',
        color:DS_BLUE,
        marginTop:10,
        marginBottom:10
    }
});

const mapStateToProps=(state)=>{
    return {
        data:state.event,
        adjudicator:state.adjudicator,
        allSections:state.sections.allSections,
        subscribed:state.sections.subscribe
    }
}

export default connect(mapStateToProps,
    {
        DemoSubScribeToSections,
        SubscribeToSections,
        OpenSection,
        RefreshSections,
        AuthLogout
    })(EventScreen);
