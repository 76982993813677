import {useReducer,}from 'react';
import {keys}from 'lodash';

const initialState={
    currentDance:null,
    marks:{},
    loading:false,
    markingModal:false,
    currentMarks:{},
    currentDancer:null,
    confirmModal:false,
    clearModal:false
};
export const FIRST_DANCE='firstDance';
export const MARKING_MODAL='markingModal';
export const OPEN_MARKING_MODAL='openMarkingModal';
export const ADD_DANCE_MARK='addDanceMark';
export const ON_CLEAR_MARK='onClearMark';
export const ON_NEXT_DANCE='onNextDance';
export const ON_FINAL_DANCE='onFinalDance';
export const CONFIRM_MODAL='confirmModal';
export const ON_CLEAR_ROUND='onClearRound';
export const CLEAR_MODAL='clearModal';
export const ON_CLEAR_MODAL='onClearModal';

const reducer=(state,{type,payload})=>{
    switch(type){
        case CLEAR_MODAL:
            return {...state,clearModal:!state.clearModal}
        case ON_CLEAR_ROUND:
            return {...state,currentMarks:{}, clearModal: false}
        case CONFIRM_MODAL:
            return {...state, confirmModal: !state.confirmModal}
        case ON_FINAL_DANCE:
            return {...state, confirmModal: false}
        case ON_NEXT_DANCE:
            return {
                ...state,
                marks:{
                    ...state.marks,
                    [state.currentDance]:state.currentMarks
                },
                currentMarks: {},
                confirmModal:false,
                currentDance: payload
            }
        case ON_CLEAR_MARK:
            const newMarks={...state.currentMarks};
            delete newMarks[state.currentDancer];
            return {
                ...state,
                currentMarks: newMarks
            }
        case ADD_DANCE_MARK:
            return {
                ...state,
                markingModal: false,
                currentDancer: null,
                currentMarks:{
                    ...state.currentMarks,
                    [state.currentDancer]:payload
                }
            }
        case OPEN_MARKING_MODAL:
            return {...state, markingModal:true,currentDancer:payload}
        case MARKING_MODAL:
            return {...state, markingModal:!state.markingModal, currentDancer:null}
        case FIRST_DANCE:
            return {...state,currentDance:payload}
        default:
            return {...state}
    }
}

export default (dancers=[])=>{

    const [state,dispatch]=useReducer(reducer,initialState);

    const handleNextDance=()=>{

    }

    const danceComplete=keys(state.currentMarks).length===dancers.length;

    return {state, dispatch, danceComplete,handleNextDance}
}
