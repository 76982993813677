import React from "react";
import {StyleSheet, View, Text, TouchableOpacity, FlatList, Platform,Modal} from "react-native-web";
import WebModal from 'modal-react-native-web';
import {values}from 'lodash';
import {DS_BLUE, GRAY} from "../../Consts/colors";
import RoundButton from "../Buttons/RoundButton";

const RenderButton=({number, selected, onPress})=>{

    return(
        <TouchableOpacity
            disabled={selected}
            onPress={()=>onPress(number)}
            style={[styles.buttonView,{backgroundColor: selected?GRAY:'#fff'}]}
        >
            <Text style={{fontSize:25   ,color:DS_BLUE}}>{number}</Text>
        </TouchableOpacity>
    )
}

const FinalMarkingModal=({visible,numbers, marks, onAddMark, markingDancer, onClose, onClear})=>{

    const Content=()=> (
        <View style={styles.container}>
            <View style={styles.view}>
                <View style={styles.textRow}>
                    <Text style={styles.text}>Dancer: {markingDancer}</Text>
                    <Text style={styles.text}>CurrentMark: {marks[markingDancer]}</Text>
                </View>
                <FlatList
                    extraData={marks}
                    contentContainerStyle = {{flexGrow: 1,justifyContent:'center'}}
                    numColumns={3}
                    data={numbers}
                    renderItem={({index})=>(
                        <RenderButton
                            number={index+1}
                            selected={values(marks).includes(index+1)}
                            onPress={onAddMark}
                        />
                    )}
                    containerStyle={{alignItems:'center'}}
                />
                <View style={styles.row}>
                    <RoundButton
                        onPress={onClear}
                        title={'Clear'}
                        color={'white'}
                        style={{marginTop:10,marginBottom:15, width:'40%'}}
                    />
                    <RoundButton
                        onPress={onClose}
                        title={'Close'}
                        color={'gold'}
                        style={{marginTop:10,marginBottom:15, width:'40%'}}
                    />
                </View>
            </View>
        </View>
    )

   return   Platform.OS==='web'?
       (
           <WebModal
               animationType="fade"
               transparent={true}
               visible={visible}
           >
               {Content()}
           </WebModal>
       )  :
       (
           <Modal
               visible={visible}
           >
               {Content()}
           </Modal>
       )

}


const styles = StyleSheet.create({
    container:{
        backgroundColor:"rgba(0,0,0,0.5)",
        height:'100%',
        width:'100%',
        alignItems:'center',
        justifyContent:'center'
    },
    view:{
        width:'90%',
        backgroundColor:DS_BLUE,
        borderRadius:15,
        alignItems:'center'
    },
    row:{
     flexDirection:'row',
     justifyContent:'space-around',
     width:'100%',
        marginLeft:15,
        marginRight:15

    },
    buttonView:{
        height:50,
        width:90,
        marginTop:15,
        marginBottom:15,
        backgroundColor:'#fff',
        borderRadius:15,
        margin:5,
        alignItems:'center',
        justifyContent: 'center',
        borderWidth:1,
        borderColor:DS_BLUE
    },
    textRow:{
        width:'90%',
        marginTop:15,
        marginBottom:10,
        marginLeft:2,
        marginRight:2,
        flexDirection:'row',
        justifyContent:'space-between'
    },
    text:{
        color:'#fff',
        fontSize:20
    }
});

export default FinalMarkingModal;

