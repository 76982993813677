
export const DS_BLUE='#143a5c';
export const DS_GOLD = '#ab8b60';
export const DS_BLUE_GRADIENT=['#3394e8','#256ba8','#143a5c','#174269',"#0f2a42"];
export const DS_GOLD_GRADIENT =['#917651','#b89567','#ab8b60','#f7c88b','#6b573c'];
export const GRAY='#8C8C8C';
export const LIGHT_GRAY='#EDEDED'
export const DARK_GREY='#525259';

export default {
    DS_BLUE,
    DS_GOLD,
    DS_BLUE_GRADIENT,
    DS_GOLD_GRADIENT,
    GRAY,
    DARK_GREY
}
