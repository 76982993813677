import React, {useState} from 'react';
import {StyleSheet, View, Text,  SafeAreaView, Image} from "react-native";
import {Button, Input, }from 'react-native-elements';
import {connect} from 'react-redux';
import Constants from 'expo-constants';
import RoundButton from "../Components/Buttons/RoundButton";
import Loading from "../Components/Loading/loading";
import {DemoLogin, AuthLogin} from "../Store/Actions/AuthActions";
import {DS_BLUE} from "../Consts/colors";

const LoginScreen=({navigation, DemoLogin, AuthLogin})=>{

    const [code, setCode]=useState('');
    const [error,setError]=useState(null);
    const [loading, setLoading]=useState(false);
    const [loadingText, setLoadingText]=useState('Loading....');

    const onSubmit=()=>{
        if(code.length!==4){
            setError('Please check your code!')
        }else{
            setError(null);
            setLoading(true);
            // check for demo
            if(code==='1111'){
                DemoLogin(code,res=>{
                    if(res.status===1){
                      setLoadingText(`Welcome ${res.name}`);
                    }else{
                        setLoading(false);
                        setError(res.msg)
                    }
                })
            }else{
                AuthLogin(code,res=>{
                    if(res.status===1){
                        // successful login
                        setLoadingText(`Welcome ${res.name}`);
                        setInterval(()=>{
                            setLoading(false);
                            navigation.navigate('App')
                        },2000)
                    }else{
                        // set error
                        setLoading(false);
                        setError(res.msg);
                    }
                })
            }
        }

    }



    return (
        <SafeAreaView style={styles.container}>
            <Loading
                visible={loading}
                text={loadingText}
            />
            <Image
                style={styles.image}
                resizeMode={'contain'}
                source={require('../../assets/Main_Logo.png')}
            />
            <View style={{flex:1,marginTop:50, alignItems:'center'}}>
                <Text style={styles.title}>Welcome to Dance Suite, the online dance competition software</Text>
                <Text style={styles.subTitle}>Please login with your code below.</Text>
                <Text style={styles.versionText}>App Version: {Constants.manifest.version}</Text>
                <Input
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={{borderBottomWidth:0}}
                    inputStyle={styles.inputStyle}
                    maxLength={4}
                    secureTextEntry
                    value={code}
                    keyboardType={'number-pad'}
                    onChangeText={(t)=>setCode(t)}
                />
                {error&&(
                    <Text style={[styles.subTitle,{color:'red'}]}>
                        {error}
                    </Text>
                )}
                <RoundButton
                    color={'blue'}
                    style={styles.button}
                    title={'Login'}
                    onPress={onSubmit}
                />
            </View>
        </SafeAreaView>
    )
}

const styles=StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'#fff',
    },
    title:{
      fontSize:20,
      fontWeight:'bold',
      textAlign:'center',
        marginTop:15,
        marginBottom:10
    },
    subTitle:{
        marginTop:10,
        fontSize:15,
        textAlign:'center'
    },
    image:{
      marginTop:50,
      height:100,
      width:'auto',
      resizeMode:'contain',
    },
    button:{
        bottom:25,
        position:'absolute',
    },
    inputContainer:{
        marginTop:30,
        width:'60%',
    },
    inputStyle:{
        marginBottom:4,
        textAlign:'center',
        borderWidth:1,
        borderColor:'grey',
        borderRadius:15,
        fontSize:22
    },
    versionText:{
        color:DS_BLUE,
        marginTop:10
    }
});

export default connect(null, {DemoLogin, AuthLogin})(LoginScreen);
