import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Text, FlatList} from "react-native";


import useRoundHook, {ADD_FINAL_MARKS, ADD_MARKS, LOAD_DANCE, MODAL} from "../../hooks/useRoundHook";
import SectionHeader from "../Header/SecitonHeader";
import MarkingButton from "../Buttons/MarkingButton";
import CurrentDanceCard from "../Cards/CurrentDanceCard";
import {CONFIRMED, SELECTED} from "../../Consts/marking";
import RoundButton from "../Buttons/RoundButton";
import ConfirmModal from "../Modals/ConfirmModal";
import {DS_BLUE} from "../../Consts/colors";



const RoundMarking=({roundData, onSubmit, index})=>{

    const {title, itemNo, roundType,dancers,callbackLimit, dances}=roundData;

    const {state,dispatch,dancerPress, progress, buttonText}=useRoundHook(callbackLimit, dances);

    const {callBacks,selected, currentDance, error, marks, confirmModal}=state;

    useEffect(()=>{
        dispatch({type:LOAD_DANCE,payload:dances[0]});
    },[]);


    const RenderItem=({item})=> {
        return (
            <MarkingButton
                title={item}
                onPress={() => dancerPress(item)}
                status={callBacks.includes(item) ? CONFIRMED : selected.includes(item) ? SELECTED : null}
            />
        )
    }


    const handleNext=()=>{
        if(buttonText==='Submit'){
            // load the finalMarks
            marks[currentDance]=callBacks;
            dispatch({type:ADD_FINAL_MARKS});

            // submit marks to database
            onSubmit(roundData,marks, index);
        }else{
            // save marks and move to next round
            dispatch({type:ADD_MARKS,payload:dances[dances.indexOf(currentDance)+1]})
        }
    }



    return (
        <View style={styles.container}>
            <ConfirmModal
                visible={confirmModal}
                onConfirm={handleNext}
                onClear={()=>dispatch({type:MODAL})}
                title={'Confirm Marks'}
                content={<Text style={{color:DS_BLUE, textAlign:'center'}}>Are you sure you have entered the correct marks ?</Text>}
            />
            <SectionHeader
                title={title}
                itemNo={itemNo}
                roundType={roundType}
                callback
                callbackLimit={callbackLimit}
                callbackNums={callBacks}
            />
            <CurrentDanceCard
                dance={currentDance}
            />
            {error&&(
                <Text style={styles.error}>{error}</Text>
            )}

            <FlatList
                extraData={{selected, callBacks}}
                numColumns={3}
                renderItem={RenderItem}
                keyExtractor={(item)=>item}
                data={dancers}
            />
            {progress &&(
                <RoundButton
                    title={buttonText}
                    onPress={()=>dispatch({type:MODAL})}
                    style={styles.button}
                    color={'blue'}
                />
            )}
        </View>
    )
}
const styles=StyleSheet.create({
    container:{
     flex:1,
    },
    error:{
        color:'red',
        textAlign:'center'
    },
    button:{
        bottom:-100,
        alignSelf:'center',
        position:'absolute',
        marginTop:15,
    }
});

export default RoundMarking;
