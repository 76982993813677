import React from 'react';
import {createStackNavigator} from "@react-navigation/stack";
import TestScreen from "../../Screens/TestScreen";

const Stack=createStackNavigator();
const {Navigator, Screen}=Stack;

export default ()=>{
    return (
        <Navigator
            headerMode={'none'}
        >
            <Screen name={'Test'}
                component={TestScreen}
            />
        </Navigator>
    )
}
