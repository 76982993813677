import React from 'react';
import {View,StyleSheet,Text, TouchableOpacity} from "react-native-web";
import {DS_BLUE} from "../../Consts/colors";

const SectionCard=({sectionData, onPress})=>(
    <TouchableOpacity style={styles.container} onPress={onPress}>
            {sectionData.map((section)=>(
                <View style={styles.view}>
                    <Text style={styles.no}>{section.itemNo}</Text>
                    <Text style={styles.name}>{section.title}</Text>
                    <Text style={styles.name}>{section.roundType}</Text>
                </View>
            ))}
    </TouchableOpacity>
);
const styles=StyleSheet.create({
    container:{
        backgroundColor:'#fff',
        marginTop:5,
        borderBottomWidth:1,
        borderBottomColor:DS_BLUE,
        minHeight:40,
        justifyContent:'center',
    },
    view:{
     flexDirection:'row',
     justifyContent:'space-between',
        marginLeft:10,
        marginRight:10
    },
    no:{
        fontSize:15,
        fontWeight:'bold'
    },
    name:{
        fontSize:13,
    }
});

export default SectionCard;
