import React from 'react';
import {createStackNavigator} from "@react-navigation/stack";
import EventScreen from "../../Screens/EventScreen";
import {STANDARD_HEADER} from "../../Components/Header/ScreenOptions";
import SectionScreen from "../../Screens/SectionScreen";
import SignatureScreen from "../../Screens/SignatureScreen";


const Stack=createStackNavigator();
const {Navigator, Screen}=Stack;

export default ()=>(
    <Navigator
        initialRouteName={'EventScreen'}
        headerMode={'float'}
    >
        <Screen
            name={'EventScreen'}
            component={EventScreen}
            options={{...STANDARD_HEADER,title:'Dance Suite - Event'}}
        />
        <Screen
            name={'SectionScreen'}
            component={SectionScreen}
            options={{...STANDARD_HEADER,title:'Dance Suite - Sections'}}
        />
        <Screen name={'Signature'}
                component={SignatureScreen}
                options={{headerShown:false}}
        />
    </Navigator>
)
