
const initialState={
    title:'',
    venue:'',
    date:'',
    scrutineer:'',
    chairperson:'',

}

export const ADD_EVENT='addEvent';
export const CLEAR_EVENT='clearEvent';

export default (state=initialState,{type,payload})=>{
    switch (type){
        case CLEAR_EVENT:
            return initialState;
        case ADD_EVENT:
            return payload
        default:
            return {...state};
    }
}
