import React, {useEffect} from 'react';
import {StyleSheet, View, Text, FlatList} from "react-native";
import {keys}from 'lodash';
import SectionHeader from "../Header/SecitonHeader";
import useFinalHook, {
    ADD_DANCE_MARK, CLEAR_MODAL, CONFIRM_MODAL,
    FIRST_DANCE,
    MARKING_MODAL,
    ON_CLEAR_MARK, ON_CLEAR_ROUND, ON_FINAL_DANCE, ON_NEXT_DANCE,
    OPEN_MARKING_MODAL
} from "../../hooks/useFinalHook";
import CurrentDanceCard from "../Cards/CurrentDanceCard";
import MarkingRow from "./MarkingRow";
import FinalMarkingModal from "../Modals/FinalMarkingModal";
import RoundButton from "../Buttons/RoundButton";
import ConfirmModal from "../Modals/ConfirmModal";
import {DS_BLUE} from "../../Consts/colors";
import {SCREEN_HEIGHT} from "../../Consts";

const FinalMarking=({roundData, onSubmit, index})=>{


    const {title, itemNo, roundType,dancers, dances}=roundData;

    const {state,dispatch, danceComplete}=useFinalHook(dancers);
    const {loading, currentDance,markingModal, currentDancer, currentMarks,marks, confirmModal, clearModal}=state;

    useEffect(()=>{
        dispatch({type:FIRST_DANCE,payload:dances[0]});
    },[])

    const RenderItem=({item})=>(
        <MarkingRow
            onMarkPress={()=>dispatch({type:OPEN_MARKING_MODAL,payload:item})}
            dancer={item}
            mark={currentMarks[item]}
        />
    )

    const onNext=()=>{

        if(keys(marks).length===dances.length-1 || dances.length===1){
            // add marks and submit to store
            dispatch({type:ON_FINAL_DANCE});
            marks[currentDance]=currentMarks;
            //submit Marks to database
            onSubmit(roundData,marks, index);
        }else{
            // add marks and clear dance
            dispatch({type:ON_NEXT_DANCE,payload:dances[dances.indexOf(currentDance)+1]})
        }
    }



    return (
        <View style={styles.container}>
            <ConfirmModal
                visible={confirmModal}
                onClear={()=>dispatch({type:CONFIRM_MODAL})}
                onConfirm={onNext}
                title={`Confirm - ${title}`}
                content={<Text style={styles.modalText}>Are you sure you have entered the correct marks ?</Text>}
            />
            <ConfirmModal
                visible={clearModal}
                onClear={()=>dispatch({type:CLEAR_MODAL})}
                onConfirm={()=>dispatch({type:ON_CLEAR_ROUND})}
                title={`Confirm Clear All - ${title}`}
                content={<Text style={styles.modalText}> Are you sure you want to clear all marks for this dance?</Text>}
            />
            <FinalMarkingModal
                marks={currentMarks}
                numbers={dancers}
                visible={markingModal}
                markingDancer={currentDancer}
                onAddMark={(number)=>dispatch({type:ADD_DANCE_MARK,payload:number})}
                onClose={()=>dispatch({type:MARKING_MODAL})}
                onClear={()=>dispatch({type:ON_CLEAR_MARK})}
            />
            <SectionHeader
                title={title}
                itemNo={itemNo}
                roundType={roundType}
            />
            <CurrentDanceCard
                dance={currentDance}
                onClear={()=>dispatch({type:CLEAR_MODAL})}
            />
            <FlatList
                data={dancers}
                renderItem={RenderItem}
                keyExctractor={item=>item}
            />
            {danceComplete &&
            (
                <View style={styles.buttonView}>
                    <RoundButton
                        title={'Next'}
                        onPress={()=>dispatch({type:CONFIRM_MODAL})}
                        color={'blue'}
                        style={styles.button}
                    />
                </View>
            )
            }
        </View>
    )
}
const styles=StyleSheet.create({
    container:{
        flex:1,
    },
    buttonStyle:{
        bottom:25,
        alignSelf:'center',
        position:'absolute'
    },
    buttonView:{
        marginTop:15,
        alignItems:'center'
    },
    modalText:{
       color:DS_BLUE,
       textAlign:'center'
    }
});

export default FinalMarking;
