import React from 'react';
import {FlatList, StyleSheet, Text, View} from "react-native-web";
import {DS_BLUE, DS_GOLD} from "../../Consts/colors";

const RenderItem=({item})=>{
    return (
        <Text style={styles.callback} >{item}</Text>
    )
}

const SectionHeader =({title,itemNo,roundType, callback, callbackNums, callbackLimit})=> (
        <View style={styles.container}>
            <View style={styles.view}>
                <Text allowFontScaling style={styles.itemNo}>{itemNo}</Text>
                <Text  allowFontScaling  style={styles.title}>{title}</Text>
                <Text  allowFontScaling  style={styles.type}>{roundType.toUpperCase()}</Text>
            </View>
            {callback&&
                <FlatList
                    style={{marginTop:15, marginBottom:10, minHeight: 25}}
                    data={callbackNums.sort((a,b)=>parseInt(a)-parseInt(b))}
                    renderItem={RenderItem}
                    horizontal
                    keyExtractor={(data)=>data}
                />
            }
            {callback &&
                <View style={styles.row}>
                    <Text style={styles.callbackText}> Total Callback: {callbackNums.length}</Text>
                    <Text style={styles.callbackText}> Callback Limit: {callbackLimit}</Text>

                </View>
            }
        </View>
);



const styles = StyleSheet.create({
    container:{
        backgroundColor:"#fff",
        borderBottomWidth:2,
        borderBottomColor:DS_BLUE,
        borderTopWidth:2,
        minHeight:70,
        justifyContent:'center'
    },
    view:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginLeft:15,
        marginRight:15,
        marginTop:10
    },
    itemNo:{
        fontSize:20,
        fontWeight:'bold',
        color:DS_BLUE,
        marginRight:10
    },
    title:{
        fontSize:18,
        color:DS_BLUE,
        marginLeft: 10
    },
    type:{
        fontSize:16,
        color:DS_BLUE
    },
    callback:{
        fontSize:13,
        fontWeight:'bold',
        color:DS_GOLD,
        marginLeft:10,
        marginRight:10,

    },
    callbackText:{
        color:DS_BLUE,
        alignSelf:'center',
        marginBottom:10
    },
    row:{
        marginRight:15,
        marginLeft:15,
        flexDirection: 'row',
        justifyContent:'space-between'
    }
})


export default SectionHeader;
