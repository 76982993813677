const initialState={
    letter:'',
    panels:[],
    name:'',
    id:null,
    eventId:null,
    deviceKey:null
}

export const LOAD_ADJ='loadAdj';
export const CLEAR_ADJ='clearAdj';

export default (state=initialState,{type,payload})=>{
    switch (type){
        case CLEAR_ADJ:
            return initialState
        case LOAD_ADJ:
            return {
                ...state,
                letter:payload.letter,
                name:payload.name,
                panels:payload.panels,
                id:payload.id,
                eventId: payload.eventId,
                deviceKey: payload.deviceKey,
            }
        default:
            return {...state}
    }
}
