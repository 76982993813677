import React from "react";
import {StyleSheet,View,Text} from "react-native-web";
import {DS_BLUE} from "../../Consts/colors";
import IconButton from "../Buttons/IconButton";

const CurrentDanceCard=({dance, onClear})=>{
    return (
        <View style={styles.container}>
            <Text style={styles.text}>{dance}</Text>
            <IconButton onPress={onClear} icon={'clear-all'} color={'gold'}/>
        </View>
    )
}
const styles=StyleSheet.create({
    container:{
        backgroundColor:DS_BLUE,
        minHeight:60,
        alignItems:'center',
        justifyContent:'space-around',
        flexDirection:'row'
    },
    text:{
        fontSize:25,
        color:"#FFF"
    }
});
export default CurrentDanceCard;
