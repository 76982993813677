import React from "react";
import {Button} from "react-native-elements";
import {StyleSheet} from "react-native";
import {DS_GOLD, DS_BLUE} from "../../Consts/colors";
import colorCheck from "./functions";


const RoundButton=({onPress,title,color, style})=>{

    return (
        <Button
            title={title}
            titleStyle={{color:color==='white'?DS_BLUE:'#fff'}}
            onPress={onPress}
            containerStyle={[styles.container,style]}
            buttonStyle={{backgroundColor:colorCheck(color)}}
        />

    )

}

const styles=StyleSheet.create({
    container:{
        borderRadius:25,
        width:'80%'
    },
    buttonStyle:{

    }
})

export default RoundButton

