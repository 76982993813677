import React from 'react';
import {StyleSheet, View,Text, Modal, Platform} from "react-native";
import RoundButton from "../Buttons/RoundButton";
import WebModal from 'modal-react-native-web';
import {DS_BLUE} from "../../Consts/colors";
import {Card} from "react-native-elements";


const ConfirmModal=({onConfirm,onClear,title,content, visible})=>{
    const Content =()=> (
        <View style={styles.container}>
            <Card containerStyle={styles.card}>
                <Card.Title style={styles.title}>{title}</Card.Title>
                <Card.Divider/>
                <Card.FeaturedSubtitle>
                    {content}
                </Card.FeaturedSubtitle>
                <View style={styles.buttonView}>
                    <RoundButton
                        title={'Cancel'}
                        onPress={onClear}
                        color={'gold'}
                        style={{width:'30%'}}
                    />
                    <RoundButton
                        title={'Confirm'}
                        onPress={onConfirm}
                        color={'blue'}
                        style={{width:'30%'}}
                    />
                </View>
            </Card>
        </View>
    );
    return Platform.OS ==='web'?
        (
            <WebModal
                animationType="fade"
                transparent={true}
                visible={visible}
            >
                {Content()}
            </WebModal>
        ):
        <Modal
            visible={visible}
        >
            {Content()}
        </Modal>

}


const styles=StyleSheet.create({
    container:{
        backgroundColor:'rgba(0,0,0,.5)',
        justifyContent:'center',
        alignItems:'center',
        flex:1,
    },
    card:{
        borderRadius:5
    },
    title:{
        fontSize:25,
        color:DS_BLUE,
    },
    content:{
        margin:15
    },
    buttonView:{
        marginTop:15,
        flexDirection:'row',
        justifyContent: 'space-around'
    }
});

export default ConfirmModal;
