const initialState={
    allSections:[],
    judging:[],
    subscribed:false
};

export const SUB_SECTIONS='subSections';
export const OPEN_SECTION='openSection';
export const CLOSE_SECTION='closeSection';
export const CLEAR_SECTIONS='clearSections';

export default (state=initialState,{type,payload})=>{
    switch(type){
        case CLOSE_SECTION:
            return {...state,judging: []}
        case SUB_SECTIONS:
            return {...state, allSections:payload, subscribed: true}
        case OPEN_SECTION:
            return {...state,judging: payload}
        default:
            return {...state};
    }
}
