import React from "react";
import {Button} from "react-native-elements";
import {DS_BLUE} from "../../Consts/colors";

const ClearButton=({onPress, title, style})=>(
    <Button
        title={title}
        onPress={onPress}
        type={'clear'}
        containerStyle={[style,]}
        titleStyle={{color:DS_BLUE}}
    />
)
export default ClearButton;
