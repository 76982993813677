import {DS_BLUE, DS_GOLD} from "../../Consts/colors";

export const colorCheck=(color)=>{
    switch (color){
        case 'white':
            return '#fff';
        case 'blue':
            return DS_BLUE;
        case 'gold':
            return DS_GOLD;
        default:
            return
    }
}

export default colorCheck;
